@import "node_modules/mathsass/dist/math"; // required for compass replacement

/*
Background
*/

@mixin background($background) {
  background: $background;
}

@mixin background-image($background) {
  background-image: $background;
}


/*
Border radius
*/

@mixin border-radius($radius) {
  border-radius: $radius;
}

// Round top-left corner only
@mixin border-top-left-radius($radius) {
  border-top-left-radius: $radius;
}

// Round top-right corner only
@mixin border-top-right-radius($radius) {
  border-top-right-radius: $radius;
}

// Round bottom-left corner only
@mixin border-bottom-left-radius($radius) {
  border-bottom-left-radius: $radius;
}

// Round bottom-right corner only
@mixin border-bottom-right-radius($radius) {
  border-bottom-right-radius: $radius;
}

// Round both top corners by amount
@mixin border-top-radius($radius) {
  border-top-right-radius: $radius;
  border-top-left-radius: $radius;
}

// Round both right corners by amount
@mixin border-right-radius($radius) {
  border-top-right-radius: $radius;
  border-bottom-right-radius: $radius;
}

// Round both bottom corners by amount
@mixin border-bottom-radius($radius) {
  border-bottom-left-radius: $radius;
  border-bottom-right-radius: $radius;
}

// Round both left corners by amount
@mixin border-left-radius($radius) {
  border-top-left-radius: $radius;
  border-bottom-left-radius: $radius;
}


/*
Box shadow
*/

@mixin box-shadow($shadow...) {
  box-shadow: $shadow;
}

/*
Clearfix
*/

@mixin pie-clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}


/*
Text Shadow
*/

@mixin text-shadow($shadow...) {
  text-shadow: $shadow;
}


/*
Transforms
*/

@mixin transform($transformation) {
  transform: $transformation;
}

@mixin transform-origin($origin...) {
  transform-origin: $origin;
}

@mixin rotate($deg...) {
  transform: rotate($deg);
}

@mixin skew($deg...) {
  transform: skew($deg);
}


/*
Transitions
*/

@mixin transition($transition...) {
  transition: $transition;
}
