#{$theme-scope} {

  .sn-table {
    font-size: $sn-font-size-sm;
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    &__sub-header {
      font-size: $sn-font-size-xs;
      font-weight: normal;
      color: $se-gray-700;
    }

    > thead > tr > th {
      @include sn-font;
      text-transform: capitalize;
    }

    // All cells
    > * > tr > * {
      padding: $sn-spacing-condensed / 2 $sn-spacing-condensed;
      &:first-child {
        padding-left: $sn-spacing-condensed * 2;
      }
      &:last-child {
        padding-right: $sn-spacing-condensed * 2;
      }
    }

    > thead, tfoot {
      > tr {
        > th {
          background: $gray-light;
          text-align: left;
          color: $sn-base-color--44;

          // Sortable column classes
          &.sn-sortable-column {
            cursor: pointer;

            svg.sn-sort-icon {
              height: 0.75em;
              width: 0.75em;
              vertical-align: -.1em;
              fill: $sn-base-color-36;
            }

            &.sn-sortable-active {
              background-color: $color-gray-20;

              svg.sn-sort-icon { fill: $sn-base-color--44; }
            }
          }

          > .sn-button > svg.icon { margin-bottom: 0; }

          > .sn-button,
          > label.sn-checkbox { @include sn-font-sc; font-size: 100%; }
        }
      }
    }

    > tbody {
      > tr {

        > td {
          text-align: left;

          // Override the default cell padding for indented cells.
          // e.g. <td class="indent-1"> for a 1-level indented item within a tree-style list
          @for $i from 1 through 6 {
            &.indent-#{$i} { padding-left: 6px + $sn-spacing-condensed * $i; }
          }
        }

        &.disabled {
          color: $sn-base-color-18;
        }

        &.error {
          background: $sn-error-color-95;
        }

        &.selected {
          color: #fff;
          background: $sn-accent-color;
        }
      }

      // Regular zebra striping
      &:nth-of-type(odd) > tr:nth-child(even) { background: $sn-base-color-90; }
      &:nth-of-type(even) > tr:nth-child(odd) { background: $sn-base-color-90; }

      // Error zebra striping
      &:nth-of-type(odd) > tr.error:nth-child(even) { background: $sn-error-color-90; }
      &:nth-of-type(even) > tr.error:nth-child(odd) { background: $sn-error-color-90; }

      // // Selected zebra striping (not striped for now, to add striping replace with darken($sn-accent-color, 4))
      &:nth-of-type(odd) > tr.selected:nth-child(even) { background: $sn-accent-color; }
      &:nth-of-type(even) > tr.selected:nth-child(odd) { background: $sn-accent-color; }
    }

    &.fixed { table-layout: fixed; }

    &.padded-top {
      [sticky-header] th { border-top: $sn-spacing solid #FFF; }
      &.sn-sticky-header, &.sn-sticky-column { margin-top: $sn-spacing / 2; } // necessary for collapsed borders
    }
    .pl-link {
      text-decoration: none;
    }
  }

  .sn-checkbox-cell {
    width: 36px;
    padding-left: $sn-spacing-condensed !important;
    padding-right: $sn-spacing-condensed / 2 !important;
  }

  .sn-table-overlay {
    background: #fff;
    opacity: .7;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: $sn-z-inline + 2;
  }

  // This is simple definition list style table
  .sn-details-table {
    font-size: $sn-font-size-sm;

    &.sn-data-large,
    .sn-data-large {
      font-size: 20px;
    }

    &.sn-data-extra-large,
    .sn-data-extra-large {
      font-size: 28px;
    }

    // global styles
    > * {
      > tr {
        > th {
          text-align: right;
          vertical-align: top;
          color: $sn-base-color;
          font-weight: inherit;
          font-size: $sn-font-size-sm;
        }
        > *:not(:last-child) { padding-right: $sn-spacing-extra-condensed; }
      }
    }

    &.sn-data-extra-large > * > tr > *,
    > *.sn-data-extra-large > tr > *,
    > * > tr.sn-data-extra-large > *,
    > * > tr > *.sn-data-extra-large {
      &:not(:last-child) { padding-right: $sn-spacing; }
    }

    > thead {
      > tr {
        > th {
          text-align: left;
          vertical-align: bottom;
        }
      }
    }

    > tfoot > tr,
    > tbody:last-child > tr {
      &:last-child > * { padding-bottom: 0; }
    }
    > tfoot ~ tbody > tr {
      &:last-child > * { padding-bottom: $sn-spacing-extra-condensed; }
    }
  }

}
