#{$theme-scope} {

  &, input, textarea, keygen, select, :not(se-fe-button) > button {
    &:not([class*="pl-"]):not([class*="nb-"]):not([class*="se-"]) {
      @include sn-font;
      font-weight: $sn-font-weight-normal;
      font-size: $sn-font-size-md;
    }
  }

  .sn-link {
    cursor: pointer;
    text-decoration: underline;
    color: $sn-accent-color;
    border: none;
    background: none;
    text-align: inherit;
    text-decoration: underline;
    text-decoration-color: $se-gray-700;
    text-decoration-skip-ink: auto;
    text-decoration-style: dotted;
    text-decoration-thickness: 1px;
    text-underline-offset: 3px;
    transition: box-shadow .1s linear,padding .1s linear,margin .1s linear,background-color .1s linear;

    &.sn-link--no-underline {
      text-decoration: none;
    }
  
    &.no-break { display: inline-block; }

    &:not(.sn-button):hover { 
      text-decoration: underline; 
      text-decoration-color: $sn-accent-color;
    }

    &[disabled], &[disabled]:hover {
      cursor: default;
      text-decoration: none;
      color: #23282b;
    }

    svg.icon,
    svg.icon-left,
    svg.icon-right {
      display: inline-block;
      fill: currentColor;
      height: 1em;
      transform: scale(1.4) translateY(12%);
      width: 1em;

      &.small {
        transform: scale(0.8);
      }
    }

    svg.icon-left { margin-right: .2em; }
    svg.icon-left.small { margin-right: 0; }
    svg.icon-right { margin-left: .2em; }
    svg.icon-right.small { margin-left: 0; }
  }


  .sn-table,
  .pl-data-table {
    %pl-link,
    .sn-link,
    .pl-link {
      text-decoration: none;
    }
  }

  .selected .sn-link { color: #fff; }

  b, strong { font-weight: $sn-font-weight-bold; }

  .sn-right { float: right; }
  .sn-left { float: left; }
  .sn-right-align { text-align: right !important; }
  .sn-left-align { text-align: left !important; }
  .sn-center-align { text-align: center !important; }
  .sn-inherit-align { text-align: inherit !important; }

  .sn-preserve-newlines { white-space: pre-line; }

  .sn-break-word {
    overflow-wrap: break-word;
    word-wrap: break-word;
  }

  .sn-ellipsis {
    display: block;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .sn-success-color {
    color: $sn-success-color !important;
    fill: $sn-success-color !important;
  }

  .sn-warning-color {
    color: $sn-warning-color !important;
    fill: $sn-warning-color !important;
  }

  .sn-error-color {
    color: $sn-error-color !important;
    fill: $sn-error-color !important;
  }

  .sn-warning-text {
    color: $sn-warning-color !important;
    &:not(.normal) { font-style: italic !important; }
  }

  .sn-error-text {
    color: $sn-error-color !important;
    &:not(.normal) { font-style: italic !important; }
  }
}
