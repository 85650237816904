.myse-dashboard {
  .myse-payment-summary {
    text-align: right;

    &__amount {
      align-items: flex-start;
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-end;
      line-height: .7;
      margin-bottom: 0.4em;
      margin-top: 6px;
    }

    &__cents {
      font-size: $se-font-size-400;
    }

    &__currency {
      font-size: $se-font-size-700;
      font-weight: $font-semibold;
    }

    &__dollars {
      font-size: $se-font-size-700;
      font-weight: $font-semibold;
      padding-right: 2px;
    }
  }
}
