.rsvp-note-modal {
  .pl-modal__header {
    background-color: $color-gray-05;
    border-bottom: none;
  }

  textarea {
    width: 100%;
    border-radius: 4px;
    border: solid 1px $color-gray-20;
    padding: 8px;
    resize: none;
  }
}