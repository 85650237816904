.myse-infinite-scroll {

  &__empty-state {
    padding-top: 36px;
    padding-bottom: 36px;
  }

  &__hide-until-ready {
    opacity: 0;
    transition: opacity 100ms linear;
  }

  &--ready &__hide-until-ready {
    opacity: 1;
  }

}
