@media (max-width: #{$tablet-width - 1}) {

  .se-list-item.se-list-item--wrap-actions {
    flex-wrap: wrap;

    .se-list-item__actions {
      flex: 0 0 calc(100% + 2em);
      margin-left: -1em;
      padding-left: 1em;
      padding-top: calc(1em + 1rem);
    }
  }

}
