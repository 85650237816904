$myse-interstitial-rem: 14px;
$myse-interstitial-max-width: 690px;
$myse-interstitial-padding-vert: $layout-spacing-large;
$myse-interstitial-padding-vert-tablet: $layout-spacing-large * 2;

$myse-interstitial-pane-padding: $layout-spacing-medium; // matches .pl-pane

$myse-interstitial-greeting-font-size-min: 1.716rem; // 24px
$myse-interstitial-greeting-font-size-max: 2.43rem; // 34px
$myse-interstitial-greeting-font-size-dynamic: 6vw;
$myse-interstitial-item-title-size: 1rem; // 16px @ 16px/rem
$myse-interstitial-item-title-size-large: 1.43rem; // 16px @ 14px/rem
$myse-interstitial-item-breakpoint: $tablet-width;
// Calculated
$myse-interstitial-greeting-breakpoint-min: ($myse-interstitial-greeting-font-size-min / 1rem) / ($myse-interstitial-greeting-font-size-dynamic / 1vw) * $myse-interstitial-rem * 100;
$myse-interstitial-greeting-breakpoint-max: ($myse-interstitial-greeting-font-size-max / 1rem) / ($myse-interstitial-greeting-font-size-dynamic / 1vw) * $myse-interstitial-rem * 100;


.myse-interstitial {
  border: 0 !important;
  min-height: 100vh;

  &__content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: calc(100vh - #{$myse-interstitial-pane-padding * 2});
    padding: $myse-interstitial-padding-vert 0;

    @media (min-width: $tablet-width) {
      min-height: 100%;
      padding: $myse-interstitial-padding-vert-tablet 0;
    }

    @media (min-width: #{$myse-interstitial-max-width + $myse-interstitial-pane-padding * 2}) {
      padding-left: calc(50% - #{$myse-interstitial-max-width / 2 - $myse-interstitial-pane-padding});
      padding-right: calc(50% - #{$myse-interstitial-max-width / 2 - $myse-interstitial-pane-padding});
    }

    > * {
      flex: 0 0 auto;
    }
  }

  &__greeting {
    color:  $se-primary-500;
    font-family: $font-default;
    font-size: $myse-interstitial-greeting-font-size-min;
    font-weight: $font-regular;
    line-height: $font-leading-headline;
    margin-bottom: $myse-interstitial-padding-vert;
    max-width: 100%;
    text-align: left;
    width: 600px;

    @media (min-width: $myse-interstitial-greeting-breakpoint-min) {
      font-size: $myse-interstitial-greeting-font-size-dynamic;
    }

    @media (min-width: $myse-interstitial-greeting-breakpoint-max) {
      font-size: $myse-interstitial-greeting-font-size-max;
    }
  }

  &__continue {
    color: $gray;
    font-size: $myse-interstitial-item-title-size-large;
    font-weight: $font-light;
    padding-bottom: 40px;
    text-align: right;


    @media (min-width: $myse-interstitial-item-breakpoint) {
      font-size: $myse-interstitial-item-title-size;
    }
  }
}
