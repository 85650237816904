.myse-event {
  $uniform-date-stamp-width: 60px;
  $day-container-bottom-padding: 20px;

  &__day-grouping {
    flex: 1;

    .se-empty-state--container {
      margin-bottom: $layout-spacing-xlarge;
      margin-top: 0px;
    }

    .se-event-card {
      &__last {
        padding-bottom: 0px;
      }
    }
  }

  &__day-label {
    min-width: $uniform-date-stamp-width;

    .se-date-stamp {
      align-self: flex-start;
      position: sticky;
      position: -webkit-sticky;
      top: 0;
      }
  }

  &__day-container {
    display: flex;
    flex-wrap: nowrap;
    padding-bottom: $day-container-bottom-padding;
    padding-top: 0px;
  }
}