$myse-interstitial-item-breakpoint: $tablet-width;

// Font sizes assume 16px rem at phone and 14px rem at tablet. Eventually, we want to be 14px rem everywhere.
$myse-interstitial-item-title-size: 1rem; // 16px @ 16px/rem
$myse-interstitial-item-title-size-large: 1.43rem; // 16px @ 14px/rem
$myse-interstitial-item-description-size: 1rem; // 12px @ 16px/rem
$myse-interstitial-item-description-size-large: 1.43rem; // 16px @ 14px/rem

.myse-interstitial-item {
  align-items:center;
  display: flex;
  flex-wrap: wrap;

  @media (min-width: $myse-interstitial-item-breakpoint) {
    flex-wrap: nowrap;
  }

  &__list {
    padding: 12px;
  }

  &__avatar {
    flex: 0 0 auto;
    padding-right: $layout-spacing-medium;

    @media (min-width: $myse-interstitial-item-breakpoint) {
      padding-right: $layout-spacing-large;
    }
  }

  &__text {
    flex: 1 1 auto;

    @media (min-width: $myse-interstitial-item-breakpoint) {
      padding-right: $layout-spacing-large;
    }
  }

  &__title {
    color: $gray;
    font-size: $myse-interstitial-item-title-size-large;
    font-weight: $font-light;
    margin-bottom: 6px;


    @media (min-width: $myse-interstitial-item-breakpoint) {
      font-size: $myse-interstitial-item-title-size;
    }
  }

  &__description {
    font-size: $myse-interstitial-item-description-size;
    font-weight: $font-semibold;
    margin-top: $layout-spacing-xsmall;

    @media (min-width: $myse-interstitial-item-breakpoint) {
      font-size: $myse-interstitial-item-description-size-large;
    }
  }

  &__actions {
    flex: 0 0 100%;

    @media (min-width: $myse-interstitial-item-breakpoint) {
      flex: 0 0 auto;
    }
  }

  &__action {
    @media (max-width: $myse-interstitial-item-breakpoint - 1) {
      margin-top: $layout-spacing-medium;
      width: 100%;
    }
  }
}
