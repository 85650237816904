$myse-dashboard-padding-vert: $layout-spacing-large;
$myse-dashboard-padding-vert-tablet: 36px;
$myse-dashboard-padding-horiz: $layout-spacing-medium + $layout-spacing-xsmall; // 16px
$myse-dashboard-padding-horiz-tablet: $layout-spacing-large;
$myse-dashboard-section-title-size: 1.43rem;
$myse-dashboard-section-title-right-padding: 2px;
$se-primary-500: #00668f;

.myse-dashboard {
  background: $gray-xlight !important;
  min-height: 100%;
  padding: $myse-dashboard-padding-vert $myse-dashboard-padding-horiz;

  @media (min-width: #{$tablet-width}) {
    padding: $myse-dashboard-padding-vert-tablet $myse-dashboard-padding-horiz-tablet;
  }

  &__full-card {

    @media (max-width: #{$tablet-width - 1}) {
      border-radius: 0 !important;
      margin: 0 (-$myse-dashboard-padding-horiz);
      width: calc(100% + #{$myse-dashboard-padding-horiz * 2}) !important; // override grid spacing
    }

    @media (min-width: #{$tablet-width}) {
      border-radius: 2px; // TODO: remove once cards are all curved
    }
  }

  &__compact-card {
    @media (max-width: #{$tablet-width - 1}) {
      padding-left: $myse-dashboard-padding-horiz !important;
      padding-right: $myse-dashboard-padding-horiz !important;
    }
  }

  &__section {
    &:not([pl-grid]) {
      display: block !important;
    }
  }

  // selector for specificity
  & &__section + &__section {
    margin-top: $layout-spacing-medium;
  }

  &__section-title {
    display: inline-block;
    font-size: $myse-dashboard-section-title-size;
    font-weight: $font-semibold;
    padding-right: $myse-dashboard-section-title-right-padding;
  }

  &__section-link {
    font-size: $se-font-size-400;
    font-weight: $font-semibold;
    line-height: $font-leading-small;
    color: $se-primary-500;
  }

  &__grid {
    margin: -24px 0 0 -12px;

    > * {
      padding: 36px 0 0 12px;
    }
  }

}
