@font-face {
  font-family: 'snAdmin';
  src: url('#{$sn-font-path}/OpenSans-Light.woff') format('woff');
  font-weight: 300;
}

@font-face {
  font-family: 'snAdmin';
  src: url('#{$sn-font-path}/OpenSans-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'snAdmin';
  src: url('#{$sn-font-path}/OpenSans-Regular.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'snAdmin';
  src: url('#{$sn-font-path}/OpenSans-Italic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'snAdmin';
  src: url('#{$sn-font-path}/OpenSans-SemiBold.woff') format('woff');
  font-weight: 700;
}

@font-face {
  font-family: 'snAdminCaps';
  src: url('#{$sn-font-path}/OpenSans-SemiBold.woff') format('woff');
}
