@mixin no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin clear-fix {
  &:before {
    content: " ";
    display: table;
  }
  &:after {
    content: " ";
    display: table;
    clear: both;
  }
}

@mixin placeholder($color) {
  &::-webkit-input-placeholder { color: $color; }
  &:-moz-placeholder { color: $color; }
  &::-moz-placeholder { color: $color; }
  &:-ms-input-placeholder { color: $color; }
}

@mixin flex-box($dir, $inline:false) {
  box-sizing: border-box;

  @if $inline {
    display: -ms-inline-flexbox;
    display: -webkit-inline-flex;
    display: inline-flex;
  } @else {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }

  -webkit-flex-direction: $dir;
  -ms-flex-direction: $dir;
  flex-direction: $dir;

  -webkit-align-items: center;
  -ms-flex-align: center;
   align-items: center;

  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;

  @if $dir == "row" {
    > * { height: 100%; }
  } @else {
    > * { width: 100%; }
  }
}
@mixin inline-flex-box($dir) {
  @include flex-box($dir, true)
}

@mixin flex($flex:1, $shrink:0, $basis:auto) {
  box-sizing: border-box;
  -webkit-flex: $flex $shrink $basis;
  -ms-flex: $flex $shrink $basis;
  flex: $flex $shrink $basis;
}


// ACTION MENU MIXINS

@mixin sn-dropdown-menu {
  position: relative;
  border: 1px solid $sn-accent-color;
  overflow: hidden;
  background: #fff;
  min-width: 100px;
  box-shadow: $sn-shadow-dropdown;
  border-radius: 2px;
  padding: $sn-dropdown-spacing-vert 0;
  font-size: $sn-font-size-sm;
}

@mixin sn-dropdown-group {
  @include sn-font-sc;
  color: $sn-base-color-54;
}

@mixin sn-dropdown-menu-item {
  padding: $sn-dropdown-spacing-vert $sn-dropdown-spacing-horiz;
  margin: 0;
  cursor: pointer;
}

@mixin sn-dropdown-menu-item-highlighted {
  background: $sn-accent-color-90;

  em { background: transparent; }
  ul { background: #fff; }
}

@mixin sn-dropdown-menu-item-disabled {
  background: none;
  color: $sn-base-color-72;
}


// ANIMATIONS

@mixin animation ($animation) {
  -webkit-animation: $animation;
  animation: $animation;
}

@mixin sn-fx-fade {
  &.sn-enter { -webkit-transition:linear $sn-animation-speed opacity; }
  &.sn-leave { -webkit-transition:linear $sn-animation-speed opacity; }
  &.sn-enter { opacity: 0; }
  &.sn-enter.sn-enter-active { opacity: 1; }
  &.sn-leave { opacity: 1; }
  &.sn-leave.sn-leave-active { opacity: 0; }
}

@-webkit-keyframes sn-spin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes sn-spin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}


// CLICKABLE (buttons, selects)

@mixin sn-clickable {
  @include no-select;
  position: relative;
  z-index: 1;
  cursor: pointer;
  background-clip: padding-box;
  @include background(linear-gradient(white, $sn-base-color-90));
  border: 1px solid $sn-base-color-54;
}

@mixin sn-clickable-hover {
  z-index: 2;
  @include background(linear-gradient($sn-base-color-90, white));
  border-color: $sn-base-color-18;
}

@mixin sn-clickable-focus {
  z-index: 2;
  border-color: $sn-accent-color;
}

@mixin sn-clickable-active {
  @include sn-clickable-hover;
  z-index: 3;
  box-shadow: inset 0 0 3px rgba(0,0,0,0.5);
  outline: none;
}

@mixin sn-clickable-disabled {
  z-index: 0;
  cursor: default;
  color: $sn-base-color-72;
  background: #ffffff;
  border-color: $sn-base-color-72;
}


// FONTS

@mixin sn-font {
  font-family: snAdmin, Georgia, Arial, 'Helvetica Neue', Helvetica, sans-serif;
  line-height: $sn-line-height;
}

@mixin sn-font-sc {
  font-family: snAdminCaps, Arial, 'Helvetica Neue', Helvetica, sans-serif;
  line-height: $sn-line-height;
  text-transform: uppercase;
  letter-spacing: 0.2px;
  font-size: 67%;
}


// ICONS

// must be applied to a pseudo element
@mixin fa-icon($icon, $size:14px) {
  font: normal normal normal $size/1 FontAwesome;
  content: $icon;
  border: 1px solid transparent;
  margin: -1px;
}


// TAGS

@mixin sn-tag-input {
  @include sn-typeable;
  font-size: $sn-font-size-xs;
  min-height: $sn-input-md;
  padding: 0 0 $sn-tag-spacing $sn-tag-spacing;
  height: auto;
  width: 100%;
  position: relative;
  cursor: text;
  overflow: hidden;
}

@mixin sn-tag {
  @include sn-font-sc;
  font-size: $sn-font-size-xs;
  position: relative;
  display: inline-block;
  list-style: none;
  color: #fff;
  background: $sn-base-color-36;
  line-height: $sn-font-size-xs;
  padding: ($sn-tag-height - $sn-font-size-xs) / 2 $sn-tag-padding-horiz;
  padding-right: $sn-tag-padding-horiz + $sn-tag-icon-size;
  border-radius: 2px;
}

@mixin sn-tag-fixed {
  color: #fff;
  background: $sn-base-color-36;
  padding-right: $sn-tag-padding-horiz;
}

@mixin sn-tag-close {
  position: absolute;
  color: inherit;
  line-height: $sn-tag-height;
  right: $sn-tag-padding-horiz / 2;
  opacity: .5;
  top: 0;
  font-size: $sn-font-size-xs;

  &:before { content: '✕'; }
  &:hover { opacity: 1; text-decoration: none; }
}


// TYPEABLE (textfield, textarea, search)

@mixin sn-placeholder-text {
  // These MUST have individual rules (not comma separated) to work correctly
  &::-webkit-input-placeholder { color: $sn-base-color-54; }
  &:-moz-placeholder { color: $sn-base-color-54; }
  &::-moz-placeholder { color: $sn-base-color-54; }
  &:-ms-input-placeholder { color: $sn-base-color-54; }
  &:disabled::-webkit-input-placeholder { color: $sn-base-color-72; }
  &:disabled:-moz-placeholder { color: $sn-base-color-72; }
  &:disabled::-moz-placeholder { color: $sn-base-color-72; }
  &:disabled:-ms-input-placeholder { color: $sn-base-color-72; }
}

@mixin sn-reset-typeable {
  font-size: $sn-font-size-sm;
  border: none;
  display: inline-block;
  vertical-align: middle;
  background: none;
  padding: 0;

  &:focus { outline: none; }
}

@mixin sn-typable-placeholder {
  @include sn-reset-typeable;
  @include sn-font;
  font-weight: $sn-font-weight-normal;
  position: relative;
  z-index: 1;
  display: inline-block;
  vertical-align: middle;
  line-height: $sn-input-md;
  color: $sn-base-color--22;
  text-align: left;
  padding: 0;
}

@mixin sn-typeable-base {
  @include sn-typable-placeholder;
  @include sn-placeholder-text;
  line-height: $sn-input-md - 2;
  border: 1px solid $sn-base-color-54;
  background: #fff;
  padding: 0 8px;
  border-radius: 2px;
}

@mixin sn-typeable {
  @include sn-typeable-base;
  height: $sn-input-md;
  &:hover { @include sn-typeable-hover; }
  &:focus { @include sn-typeable-focus; }
  &:disabled { @include sn-typeable-disabled; }
}

@mixin sn-typeable-sm {
  @include sn-typeable;
  font-size: $sn-font-size-xs;
  height: $sn-input-sm;
  line-height: $sn-input-sm;
  padding: 0 6px;
}

@mixin sn-typeable-multi-line {
  @include sn-typeable-base;
  display: block;
  padding-top: 6px;
  padding-bottom: 6px;
  line-height: $sn-line-height;
  &:hover { @include sn-typeable-hover; }
  &:focus { @include sn-typeable-focus; }
  &:disabled { @include sn-typeable-disabled; }
}

@mixin sn-typeable-hover {
  z-index: 2;
  border-color: $sn-base-color-18;
}

@mixin sn-typeable-focus {
  z-index: 3;
  border-color: $sn-accent-color;
}

@mixin sn-typeable-disabled {
  z-index: 0;
  color: $sn-base-color-72;
  border-color: $sn-base-color-72;
}
