$myse-mobile-callout-phones: url('#{$shared-image-path}/mobile_marketing/Mobile-BottomBanner-Devices@3x.png');
$myse-mobile-callout-phones-size: 272px;
$myse-mobile-callout-phones-size-tablet: 162px;
$myse-mobile-callout-phones-aspect-ratio: .9;
$myse-mobile-callout-phones-crop: .7;
$myse-mobile-callout-phones-spacing: $layout-spacing-large;
$myse-mobile-callout-links-max: 279px;
$myse-mobile-callout-links-spacing: $layout-spacing-large;
$myse-mobile-callout-itunes: url('#{$shared-image-path}/mobile_marketing/app-store.svg');
$myse-mobile-callout-play: url('#{$shared-image-path}/mobile_marketing/google-play.svg');

%myse-mobile-callout-button {
  background-repeat: no-repeat;
  background-size: contain;
  height: 0;
  overflow: hidden;
  padding-top: 14.35%;
  text-decoration: none;
}

// nesting for specificity
.myse-dashboard {
  .myse-mobile-callout {
    background-image: $myse-mobile-callout-phones;
    background-repeat: no-repeat;
    background-size: $myse-mobile-callout-phones-size;
    position: relative;

    @media (max-width: $tablet-width - 1) {
      background-position: center calc(100% + #{$myse-mobile-callout-phones-size * (1 - $myse-mobile-callout-phones-crop) / $myse-mobile-callout-phones-aspect-ratio + $myse-mobile-callout-phones-spacing});
      padding-bottom: $myse-mobile-callout-phones-size * $myse-mobile-callout-phones-crop;
    }

    @media (min-width: $tablet-width) {
      background-position: calc(100% - #{$myse-mobile-callout-phones-spacing}) 100%;
      background-size: $myse-mobile-callout-phones-size-tablet;
      padding-right: $myse-mobile-callout-phones-spacing * 2 + $myse-mobile-callout-phones-size-tablet;
    }

    @media (min-width: 720px) {
      background-position: calc(100% - #{$myse-mobile-callout-phones-spacing}) $myse-mobile-callout-phones-spacing;
    }

    &__close {
      background: none;
      border: 0;
      color: $gray;
      padding: 8px;
      position: absolute;
      right: 0;
      top: 0;

      svg {
        display: block;
        margin: 0;
      }
    }

    &__title {
      font-size: $font-medium;
      font-weight: $font-semibold;
    }

    &__description {
      color: $gray;
      font-size: $font-xsmall;
      font-weight: $font-regular;
      margin-top: $layout-spacing-xsmall;
    }

    &__links {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      margin-top: $myse-mobile-callout-links-spacing;
      position: relative;

      @media (min-width: $tablet-width) {
        max-width: $myse-mobile-callout-links-max;
      }
    }

    &__itunes-link {
      @extend %myse-mobile-callout-button;
      background-image: $myse-mobile-callout-itunes;
      flex: 0 0 43%;
    }

    &__play-link {
      @extend %myse-mobile-callout-button;
      background-image: $myse-mobile-callout-play;
      flex: 0 0 48.39%;
    }

  }
}
