$myse-dashboard-skeleton-color: $gray;

%myse-dashboard-skeleton {
  font-size: $font-small;

  &::before,
  &::after {
    @include pl-skeleton-text($myse-dashboard-skeleton-color);
    display: block;
  }

  &::after {
    margin: .7em 0 2.4em;
  }
}

.myse-dashboard-skeleton--section-card {
  @extend %myse-dashboard-skeleton;

  &::before {
    width: 70%;
  }

  &::after {
    width: 35%;
  }
}
