#{$theme-scope} {

  hr {
    border: none;
    border-top: 1px solid $sn-base-color-72;
    height: 0;
  }

  .sn-split-vert,
  .sn-split-horiz {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .sn-split-vert {
    @include flex-box(row);

    > *:not(:last-child) {
      border-right: 1px solid $sn-base-color-72;
    }
  }
  .sn-split-horiz { @include flex-box(column); }

  .sn-pane,
  .sn-pane-0,
  .sn-pane-1,
  .sn-pane-2,
  .sn-pane-3,
  .sn-pane-4,
  .sn-pane-5,
  .sn-pane-6,
  .sn-pane-7,
  .sn-pane-8,
  .sn-pane-9,
  .sn-pane-10,
  .sn-pane-11,
  .sn-pane-12, {
    background: #fff;
    position: relative;
    padding: $sn-spacing;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    &.shrink { @include flex(0); overflow: visible; }
    &.greedy { @include flex(0, 1, auto); }
    &.collapse { padding: 0; border: 0; }
    &.collapse-right { padding-right: 0; }
    &.collapse-left { padding-left: 0; }
    &.collapse-top { padding-top: 0; }
    &.collapse-bottom { padding-bottom: 0; }
    &.collapse-y { padding-bottom: 0; padding-top: 0; }
    &.condense-y { padding-bottom: $sn-spacing-condensed; padding-top: $sn-spacing-condensed; }
    &.condense-top { padding-top: $sn-spacing-condensed; }
  }

  .sn-overflow-pane { overflow: visible; }
  .sn-no-scroll-pane { overflow: hidden !important; }
  .sn-pane-inner-scroller {
    bottom: $sn-spacing;
    left: $sn-spacing;
    overflow: auto;
    position: absolute;
    right: $sn-spacing;
    top: $sn-spacing;
    z-index: 0;
  }

  .collapse,
  .collapse-y,
  .collapse-top {
    > .sn-pane-inner-scroller {
      top: 0;
    }
  }

  .collapse,
  .collapse-y,
  .collapse-bottom {
    > .sn-pane-inner-scroller {
      bottom: 0;
    }
  }

  .collapse,
  .collapse-x,
  .collapse-right {
    > .sn-pane-inner-scroller {
      right: 0;
    }
  }

  .collapse,
  .collapse-x,
  .collapse-left {
    > .sn-pane-inner-scroller {
      left: 0;
    }
  }

  .sn-pane { @include flex(1,1000,100%); }
  .sn-pane-0 { @include flex(0,0,0px); opacity: 0; padding: 0; border-width: 0; @include transition(all, 300ms); }
  .sn-pane-1 { @include flex(0,0,8.3333333333%); @include transition(all, 300ms); }
  .sn-pane-2 { @include flex(0,0,16.6666666666%); @include transition(all, 300ms); }
  .sn-pane-3 { @include flex(0,0,25%); @include transition(all, 300ms); }
  .sn-pane-4 { @include flex(0,0,33.3333333333%); @include transition(all, 300ms); }
  .sn-pane-5 { @include flex(0,0,41.6666666666%); @include transition(all, 300ms); }
  .sn-pane-6 { @include flex(0,0,50%); @include transition(all, 300ms); }
  .sn-pane-7 { @include flex(0,0,58.3333333333%); @include transition(all, 300ms); }
  .sn-pane-8 { @include flex(0,0,66.6666666666%); @include transition(all, 300ms); }
  .sn-pane-9 { @include flex(0,0,75%); @include transition(all, 300ms); }
  .sn-pane-10 { @include flex(0,0,83.3333333333%); @include transition(all, 300ms); }
  .sn-pane-11 { @include flex(0,0,91.6666666666%); @include transition(all, 300ms); }
  .sn-pane-12 { @include flex(0,0,100%); @include transition(all, 300ms); }

  // Percent Widths

  .sn-x0   { width: 0; }
  .sn-x1   { width: 1%; }
  .sn-x2   { width: 2%; }
  .sn-x3   { width: 3%; }
  .sn-x4   { width: 4%; }
  .sn-x5   { width: 5%; }
  .sn-x6   { width: 6%; }
  .sn-x7   { width: 7%; }
  .sn-x8   { width: 8%; }
  .sn-x9   { width: 9%; }
  .sn-x10  { width: 10%; }
  .sn-x15  { width: 15%; }
  .sn-x20  { width: 20%; }
  .sn-x25  { width: 25%; }
  .sn-x33  { width: 33.3333333333%; }
  .sn-x35  { width: 35%; }
  .sn-x40  { width: 40%; }
  .sn-x45  { width: 45%; }
  .sn-x50  { width: 50%; }
  .sn-x55  { width: 55%; }
  .sn-x60  { width: 60%; }
  .sn-x65  { width: 65%; }
  .sn-x66  { width: 66.6666666666%; }
  .sn-x70  { width: 70%; }
  .sn-x75  { width: 75%; }
  .sn-x80  { width: 80%; }
  .sn-x85  { width: 85%; }
  .sn-x90  { width: 90%; }
  .sn-x95  { width: 95%; }
  .sn-x100 { width: 100%; }

  // Pixel Widths (10 to 100 by 10) - (20 to 200 by 20) - (50 to 1000 by 50)

  .sn-x10px   { width: 10px; }
  .sn-x20px   { width: 20px; }
  .sn-x30px   { width: 30px; }
  .sn-x40px   { width: 40px; }
  .sn-x50px   { width: 50px; }
  .sn-x60px   { width: 60px; }
  .sn-x70px   { width: 70px; }
  .sn-x80px   { width: 80px; }
  .sn-x90px   { width: 90px; }
  .sn-x100px  { width: 100px; }
  .sn-x120px  { width: 120px; }
  .sn-x140px  { width: 140px; }
  .sn-x150px  { width: 150px; }
  .sn-x160px  { width: 160px; }
  .sn-x180px  { width: 180px; }
  .sn-x200px  { width: 200px; }
  .sn-x250px  { width: 250px; }
  .sn-x350px  { width: 350px; }
  .sn-x400px  { width: 400px; }
  .sn-x450px  { width: 450px; }
  .sn-x500px  { width: 500px; }
  .sn-x550px  { width: 550px; }
  .sn-x600px  { width: 600px; }
  .sn-x650px  { width: 650px; }
  .sn-x700px  { width: 700px; }
  .sn-x750px  { width: 750px; }
  .sn-x800px  { width: 800px; }
  .sn-x850px  { width: 850px; }
  .sn-x900px  { width: 900px; }
  .sn-x950px  { width: 950px; }
  .sn-x1000px { width: 1000px; }

  // Content Layout

  .sn-content {
    width: 100%;
    max-width: 500px;

    .large { max-width: 600px; }
    .extra-large { max-width: 700px; }
  }

  $action-width: 16px;

  .sn-row {
    display: block;
    position: relative;
    margin: 0 0 0 0-$sn-spacing;
    padding: 0;

    &:before {
      content: " ";
      display: table;
    }

    &:after {
      content: " ";
      display: table;
      clear: both;
    }

    &.condense-x.condense-y,
    &.condense { margin: 0 0 0 0-$sn-spacing-condensed; }
    &.condense-x { margin: 0 0 0 0-$sn-spacing-condensed; }
    &.condense-y { margin: 0 0 0 0-$sn-spacing; }

    &.collapse,
    &.collapse-x { margin: 0; }

    &.with-1-action { padding-right: $sn-spacing-extra-condensed + $action-width; }
    &.with-2-actions { padding-right: $sn-spacing-extra-condensed + $action-width*2; }
    &.with-3-actions { padding-right: $sn-spacing-extra-condensed + $action-width*3; }
    &.with-4-actions { padding-right: $sn-spacing-extra-condensed + $action-width*4; }
    &.with-5-actions { padding-right: $sn-spacing-extra-condensed + $action-width*5; }
  }

  .sn-row-actions {
    position: absolute;
    right: 0;

    > * {
      float: left;
      width: $action-width;
      margin: 0 !important;
      text-align: right;
    }
  }

  .sn-row-help-action {
    padding-top: 6px;
  }

  .sn-col { max-width: 100%; }
}

// THESE CANNOT BE NESTED

$sn-cols: (
  sn-col     auto,
  sn-col-1   8.3333333333%,
  sn-col-2   16.6666666666%,
  sn-col-3   25%,
  sn-col-4   33.3333333333%,
  sn-col-5   41.6666666666%,
  sn-col-6   50%,
  sn-col-7   58.3333333333%,
  sn-col-8   66.6666666666%,
  sn-col-9   75%,
  sn-col-10  83.3333333333%,
  sn-col-11  91.6666666666%,
  sn-col-12  100%
);

@each $val in $sn-cols {

  $col: nth($val, 1);
  $width: nth($val, 2);

  #{$theme-scope} .#{$col} {
    width: #{$width};
    display: block;
    float: left;
    padding-left: $sn-spacing;
    margin-bottom: $sn-spacing;
  }

  #{$theme-scope} .condense > .#{$col},
  #{$theme-scope} .condense-x > .#{$col} { padding-left: $sn-spacing-condensed; }
  #{$theme-scope} .condense > .#{$col},
  #{$theme-scope} .condense-y > .#{$col} { margin-bottom: $sn-spacing-condensed; }

  #{$theme-scope} .extra-condense > .#{$col},
  #{$theme-scope} .extra-condense-x > .#{$col} { padding-left: $sn-spacing-extra-condensed; }
  #{$theme-scope} .extra-condense > .#{$col},
  #{$theme-scope} .extra-condense-y > .#{$col} { margin-bottom: $sn-spacing-extra-condensed; }

  #{$theme-scope} .collapse > .#{$col},
  #{$theme-scope} .collapse-x > .#{$col} { padding-left: 0; }
  #{$theme-scope} .collapse > .#{$col},
  #{$theme-scope} .collapse-y > .#{$col} { margin-bottom: 0; }

}
