/*
 * NOTE: This file is shared in the billing theme (BackOffice)
 */

.pl-survey-results {

  $font-large: 18pt;
  $font-medium: 12pt;
  $font-small: 10pt;
  $text-color: #000;
  $text-color-light: #888;
  $border: .6pt solid #CCC; // .5pt was disappearing sometimes in Chrome

  $spacing-small: 4pt;
  $spacing-medium: 12pt;
  $spacing-large: 36pt;

  $avatar-size: 108pt;
  $avatar-indent: $avatar-size + $spacing-large;
  $info-label-width: 100pt;

  color: $text-color;
  font-size: $font-small;
  font-weight: 200;
  text-align: left;

  * {
    text-align: inherit;
    font-weight: inherit;
  }

  &__header {
    @include clear-after;
  }

  &__avatar {
    border: $border;
    border-radius: 50%;
    float: left;
    height: $avatar-size;
    overflow: hidden;
    width: $avatar-size;

    .pl-user-avatar__image,
    .pl-user-avatar__image--custom {
      height: 100%;
      width: 100%;
    }

    &-image {
      height: 100%;
      width: 100%;
    }

    // all other header items, if avatar is present
    ~ * {
      overflow: hidden; // forces divs to the left of the float
      padding-left: $avatar-indent - $avatar-size;
    }
  }

  &__info-title {
    font-family: $font-heading-alternate;
    font-size: $font-large;
    font-weight: $font-bold;
  }

  &__info-subtitle {
    color: $text-color-light;
    font-size: $font-medium;
  }

  &__info-list {
    display: flex;
    flex-wrap: wrap;
    font-size: $font-small;
  }

  &__info-label {
    flex: 0 0 $info-label-width;
    font-weight: 600;
  }

  &__info-value {
    flex: 0 0 calc(100% - #{$info-label-width});
    margin: 0;
    padding-left: $spacing-medium;
  }

  // table
  &__form {
    width: 100%;
  }

  // Form name (first page)
  &__form-header {
    background: #fff;
    font-family: $font-heading-alternate;
    font-size: $font-large;
    font-weight: $font-bold;
    margin-bottom: -$font-small - $spacing-medium; // total size of &__form-header--continued and its content
    padding: $spacing-large 0 $spacing-medium 0;
    position: relative;
    z-index: 2;

    // divider line
    &::before {
      border-bottom: $border;
      content: '';
      display: block;
      height: 0;
      margin-bottom: $spacing-medium;
    }
  }

  // thead th (repeated every page)
  &__form-header--continued {
    // all styles are on the &__form-name for now
  }

  // Form name (repeated on page 2+ in suported browsers)
  &__form-name {
    color: $text-color-light;
    font-family: $font-heading;
    font-size: $font-small;
    font-weight: 200;
    line-height: 1;
    height: $font-small;
    padding-bottom: $spacing-medium;
  }

  // tbody
  &__form-results {
    border-top: $spacing-medium solid transparent;
  }

  %pl-survey-results-cell {
    font-size: $font-small;
    margin: 0;
    padding: .5em 0;
    vertical-align: top;
  }

  // body th
  &__question {
    @extend %pl-survey-results-cell;
    font-weight: 400;
    padding-right: 1em !important;
    width: $avatar-indent;
  }

  // body td
  &__answer {
    @extend %pl-survey-results-cell;
  }
}
